import React from 'react';
import FeaturesComponent from '@/ui/shared/components/Landing/Sections/Features';
import Section from '@/ui/shared/components/Landing/Sections/Section';
import SectionContainer from '@/ui/shared/components/Landing/Sections/SectionContainer';
import SectionHeader from '@/ui/shared/components/Landing/Sections/SectionHeader';
import { featuresTabsList } from './tabs';
import styles from './features.scss';

interface IProps {
  title: string,
  description: string,
  actionButton: React.ReactNode,
}

const Features = ({ title, description, actionButton }: IProps) => (
  <Section padding="top" className={ styles.sectionWrapper } dataTest="featuresSection">
    <SectionContainer>
      <SectionHeader
        title={ title }
        description={ description }
        maxWidth={ 900 }
      />
    </SectionContainer>
    <SectionContainer size="large" narrow>
      <FeaturesComponent
        actionButton={ actionButton }
        tabsList={ featuresTabsList }
        autoplay
      />
    </SectionContainer>
  </Section>
);

export default Features;
